import logo from './BX-2.png';
import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <p className='App-title'>
          Non-fungible tokens, or NFTs, are pieces of digital content linked to
          the blockchain, the digital database underpinning cryptocurrencies
          such as bitcoin and ethereum. Unlike NFTs, those assets are fungible,
          meaning they can be replaced or exchanged with another identical one
          of the same value, much like a dollar bill.
        </p>
        {/* <a
          className='App-link'
          href='https://reactjs.org'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
